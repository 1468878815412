/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, Hidden, List, makeStyles } from '@material-ui/core';
import LogoWhite from 'src/components/LogoWhite';
import NavItem from './NavItem';
import { THEMES } from 'src/constants';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { navConfig } from 'src/constants/navConfig';

const useStyles = makeStyles(theme => ({
  root: {
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: 'white'
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default
        }
      : {})
  },
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 0,
    height: '100%',
    zIndex: theme.zIndex.drawer,
    whiteSpace: 'nowrap'
  },
  menuIcon: {
    zIndex: 1400,
    position: 'absolute',
    top: '50px',
    left: '10px',
    borderRadius: '10px',
    backgroundColor: '#334A5B',
    width: '30px',
    height: '30px',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard
    })
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  logo: {
    width: 140,
    maxWidth: '100%'
  }
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const {
    user: { permissions }
  } = useSelector(state => state.account);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const permissionsArray = permissions.map(permission => permission.accessTo);

  function renderNavItems({ items, ...rest }) {
    return (
      <List disablePadding>
        {items.reduce((acc, item) => reduceChildRoutes({ acc, item, ...rest }), [])}
      </List>
    );
  }

  function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
    const key = item.title + depth;
    const titlePermission = item.permission.toLowerCase().replace(/ /g, '_');
    if (permissionsArray.includes(titlePermission)) {
      if (item.items) {
        const open = matchPath(pathname, {
          path: item.href,
          exact: false
        });

        acc.push(
          <NavItem
            depth={depth}
            icon={item.icon}
            key={key}
            info={item.info}
            open={Boolean(open)}
            title={item.title}
          >
            {renderNavItems({
              depth: depth + 1,
              pathname,
              items: item.items
            })}
          </NavItem>
        );
      } else {
        acc.push(
          <NavItem
            depth={depth}
            href={item.href}
            icon={item.icon}
            key={key}
            info={item.info}
            title={item.title}
          />
        );
      }
    }

    return acc;
  }

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      className={clsx(classes.root)}
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box minHeight={72} p={2} display="flex" justifyContent="left">
          <RouterLink to="/">
            <LogoWhite className={classes.logo} />
          </RouterLink>
        </Box>
        <Box>
          {navConfig.map(config => (
            <List key={config.subheader}>
              {renderNavItems({
                items: config.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
