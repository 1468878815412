import {
  Assessment,
  AssessmentOutlined,
  LibraryBooks,
  PieChart,
  Receipt
} from '@material-ui/icons';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import {
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  Shield as ShieldIcon,
  ArrowUpLeft as PickingIcon,
  ArrowDownRight as PutBackIcon,
  Airplay as QcIcon,
  Cpu as KittingIcon,
  Printer as PrintingIcon,
  ArrowLeft as ReturnsIcon,
  ArrowRight as DispatchIcon,
  Users as MerchantIcon,
  Clipboard as CycleCountIcon,
  Package as PackingIcon,
  SkipForward as PlanningIcon,
  Eye
} from 'react-feather';

export const navConfig = [
  {
    subheader: 'Actions',
    items: [
      {
        title: 'Financials',
        permission: 'Financials',
        href: '/app/financials',
        icon: AssessmentOutlined
      },
      {
        title: 'Analytics',
        permission: 'Analytics',
        href: '/app/analytics',
        icon: PieChart
      },
      {
        title: 'Products',
        permission: 'Products',
        href: '/app/products',
        icon: ShoppingCartIcon
      },
      {
        title: 'Categories',
        permission: 'Categories',
        icon: ShoppingCartIcon,
        href: '/app/category'
      },

      {
        title: 'Bins',
        permission: 'Bins',
        icon: ShoppingCartIcon,
        href: '/app/inventory/bin/transactions/'
      },
      {
        title: 'Bin Transactions',
        permission: 'Bin Transactions',
        icon: ShoppingCartIcon,
        href: '/app/inventory/bin/transactions/create'
      },
      {
        title: 'Orders',
        permission: 'Orders',
        icon: FolderIcon,
        href: '/app/orders'
      },
      {
        title: 'Order Products',
        permission: 'Order Products',
        icon: FolderIcon,
        href: '/app/orderproducts'
      },
      {
        title: 'Batches',
        permission: 'Batches',
        icon: ReceiptIcon,
        href: '/app/order/batches'
      },
      {
        title: 'Planning',
        permission: 'Planning',
        icon: PlanningIcon,
        href: '/app/order/planning'
      },
      {
        title: 'Picking',
        permission: 'Picking',
        icon: PickingIcon,
        href: '/app/order/picking/station'
      },
      {
        title: 'Put Back',
        permission: 'Put Back',
        icon: PutBackIcon,
        href: '/app/order/put_back/station'
      },
      {
        title: 'Preview',
        permission: 'Printing',
        icon: Eye,
        href: '/app/order/preview'
      },
      {
        title: 'Printing',
        permission: 'Printing',
        icon: PrintingIcon,
        href: '/app/order/printing'
      },
      {
        title: 'QC',
        permission: 'QC',
        icon: QcIcon,
        href: '/app/order/qc'
      },
      {
        title: 'Kitting',
        permission: 'Kitting',
        icon: KittingIcon,
        href: '/app/order/kitting'
      },
      {
        title: 'Packing',
        permission: 'Packing',
        icon: PackingIcon,
        href: '/app/order/packing'
      },
      {
        title: 'Returns',
        permission: 'Returns',
        icon: ReturnsIcon,
        href: '/app/order/returns'
      },
      {
        title: 'Reverse Orders',
        permission: 'Reverse Orders',
        icon: ReturnsIcon,
        href: '/app/reverse_orders'
      },
      {
        title: 'Dispatch',
        permission: 'Dispatch',
        icon: DispatchIcon,
        href: '/app/order/dispatch'
      },
      {
        title: 'Cycle Count',
        permission: 'Cycle Count',
        icon: CycleCountIcon,
        href: '/app/cycle_count'
      },
      {
        title: 'Purchase Orders',
        permission: 'Purchase Orders',
        icon: ReturnsIcon,
        href: '/app/purchase_orders'
      },
      {
        title: 'Purchase Receives',
        permission: 'Purchase Receives',
        icon: ReturnsIcon,
        href: '/app/receives'
      },
      {
        title: 'Purchase Bills',
        permission: 'Purchase Receives',
        icon: Receipt,
        href: '/app/purchase_bills'
      },
      {
        title: 'Merchants',
        permission: 'Merchants',
        icon: MerchantIcon,
        href: '/app/merchants'
      },
      {
        title: 'Product Library',
        permission: 'Product Library',
        icon: LibraryBooks,
        href: '/app/product_library'
      },
      {
        title: 'Remittances',
        permission: 'Remittances',
        icon: ReceiptIcon,
        href: '/app/remittances'
      },
      {
        title: 'NDRs',
        permission: 'Orders',
        icon: ReturnsIcon,
        href: '/app/ndrs'
      },
      {
        title: 'Users',
        permission: 'Users',
        href: '/app/users',
        icon: LockIcon
      }
    ]
  }
];
