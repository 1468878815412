import React from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import PublicActionBoard from './PublicActionBoard';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'aliceblue'
  }
}));

function HomeView() {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Home">
      <PublicActionBoard />
    </Page>
  );
}

export default HomeView;
