let backendHost;
const hostname = window && window.location && window.location.hostname;

if (hostname === 'admin.printrove.com') {
  backendHost = 'https://api.printrove.com';
} else if (/staging/.test(hostname) || /amplify/.test(hostname)) {
  backendHost = 'https://stagingapi.printrove.com';
} else if (/live/.test(hostname)) {
  backendHost = 'https://stagingapi.printrove.com';
} else {
  backendHost = 'http://ptapi.test';
}

export const API_ROOT = `${backendHost}/api/admin`;
