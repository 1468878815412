import React, { lazy, Suspense } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthRoute from 'src/components/AuthRoute';
import GuestRoute from 'src/components/GuestRoute';
import { useSelector } from 'react-redux';
import HomeView from './views/pages/HomeView';
import MainLayout from './layouts/MainLayout';

function Routes() {
  const { user } = useSelector(state => state.account);
  let permissionsArray;
  if (user && user.permissions) {
    permissionsArray = user.permissions.map(permission => permission.accessTo);
  }

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        <Redirect exact from="/" to="/app" />
        <Route
          exact
          path="/404"
          component={lazy(() => import('src/views/pages/Error404View'))}
        />
        <GuestRoute
          exact
          path="/login"
          component={lazy(() => import('src/views/auth/LoginView'))}
        />
        {/* <Route
          exact
          path="/login-unprotected"
          component={lazy(() => import('src/views/auth/LoginView'))}
        />
        <GuestRoute
          exact
          path="/register"
          component={lazy(() => import('src/views/auth/RegisterView'))}
        />
        <Route
          exact
          path="/register-unprotected"
          component={lazy(() => import('src/views/auth/RegisterView'))}
        /> */}
        <AuthRoute
          path="/app"
          render={props => (
            <DashboardLayout {...props}>
              <Suspense fallback={<LoadingScreen />}>
                <Switch>
                  <Redirect exact from="/app" to="/app/dashboard" />
                  <Route
                    exact
                    path="/app/dashboard"
                    component={lazy(() => import('src/views/dashboards/HomeDashboard'))}
                  />
                  <Redirect exact from="app/reports" to="/app/dashboard" />
                  {permissionsArray && permissionsArray.includes('users') && (
                    <Route
                      exact
                      path="/app/users"
                      component={lazy(() =>
                        import('src/views/users/ListUsers/ListUsers')
                      )}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('users') && (
                    <Route
                      exact
                      path="/app/users/create"
                      component={lazy(() => import('src/views/users/CreateUser/index'))}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('users') && (
                    <Route
                      exact
                      path="/app/users/update/:id"
                      component={lazy(() => import('src/views/users/UpdateUser'))}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('merchants') && (
                    <Route
                      exact
                      path="/app/merchants"
                      component={lazy(() => import('src/views/merchants/GetMerchant'))}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('merchants') && (
                    <Route
                      exact
                      path="/app/merchants/:id"
                      component={lazy(() => import('src/views/merchants/MerchantView'))}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('orders') && (
                    <Route
                      exact
                      path="/app/ndrs"
                      component={lazy(() => import('src/views/orders/Ndrs'))}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('remittances') && (
                    <Route
                      exact
                      path="/app/remittances"
                      component={lazy(() => import('src/views/Billing/ListRemittances'))}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('products') && (
                    <Route
                      exact
                      path="/app/products/"
                      component={lazy(() => import('src/views/products/ListProducts'))}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('products') && (
                    <Route
                      exact
                      path="/app/products/create"
                      component={lazy(() =>
                        import('src/views/products/CreateOrUpdateProduct/CreateProduct')
                      )}
                    />
                  )}

                  {permissionsArray && permissionsArray.includes('products') && (
                    <Route
                      exact
                      path="/app/products/update/:id"
                      component={lazy(() =>
                        import('src/views/products/CreateOrUpdateProduct/UpdateProduct')
                      )}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('categories') && (
                    <Route
                      exact
                      path="/app/category/"
                      component={lazy(() =>
                        import('src/views/products/ListCategories/ListCategories')
                      )}
                    />
                  )}

                  {permissionsArray && permissionsArray.includes('categories') && (
                    <Route
                      exact
                      path="/app/category/create"
                      component={lazy(() =>
                        import('src/views/products/CreateOrUpdateCategory/CreateCategory')
                      )}
                    />
                  )}

                  {permissionsArray && permissionsArray.includes('categories') && (
                    <Route
                      exact
                      path="/app/category/update/:id"
                      component={lazy(() =>
                        import('src/views/products/CreateOrUpdateCategory/UpdateCategory')
                      )}
                    />
                  )}

                  {permissionsArray && permissionsArray.includes('bin_transactions') && (
                    <Route
                      exact
                      path="/app/inventory/bin/transactions/create"
                      component={lazy(() =>
                        import('src/views/inventory/CreateBinTransactions')
                      )}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('bins') && (
                    <Route
                      exact
                      path="/app/inventory/bin/transactions/createbin"
                      component={lazy(() => import('src/views/inventory/CreateBin'))}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('bins') && (
                    <Route
                      exact
                      path="/app/inventory/bin/transactions/"
                      component={lazy(() =>
                        import('src/views/inventory/ListBins/ListBins')
                      )}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('planning') && (
                    <Route
                      exact
                      path="/app/order/planning"
                      component={lazy(() => import('src/views/orders/Planning'))}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('picking') && (
                    <Route
                      exact
                      path="/app/order/picking/station"
                      component={lazy(() => import('src/views/orders/Picking'))}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('picking') && (
                    <Route
                      exact
                      path="/app/order/picking/start/:id"
                      component={lazy(() =>
                        import('src/views/orders/Picking/StartPicking')
                      )}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('put_back') && (
                    <Route
                      exact
                      path="/app/order/put_back/station"
                      component={lazy(() => import('src/views/orders/PutBack'))}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('put_back') && (
                    <Route
                      exact
                      path="/app/order/put_back/start/:id"
                      component={lazy(() =>
                        import('src/views/orders/PutBack/StartPutBack')
                      )}
                    />
                  )}

                  {permissionsArray && permissionsArray.includes('cycle_count') && (
                    <Route
                      exact
                      path="/app/cycle_count"
                      component={lazy(() => import('src/views/CycleCount'))}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('cycle_count') && (
                    <Route
                      exact
                      path="/app/cycle_count/start/:id"
                      component={lazy(() => import('src/views/CycleCount/StartCounting'))}
                    />
                  )}

                  {permissionsArray && permissionsArray.includes('purchase_orders') && (
                    <Route
                      exact
                      path="/app/purchase_orders"
                      component={lazy(() => import('src/views/inventory/PurchaseOrders'))}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('purchase_receives') && (
                    <Route
                      exact
                      path="/app/receives"
                      component={lazy(() =>
                        import('src/views/inventory/PurchaseReceives')
                      )}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('purchase_receives') && (
                    <Route
                      exact
                      path="/app/purchase_bills"
                      component={lazy(() => import('src/views/inventory/PurchaseBills'))}
                    />
                  )}

                  {permissionsArray && permissionsArray.includes('purchase_receives') && (
                    <Route
                      exact
                      path="/app/purchase_bills/:id/create_receive"
                      component={lazy(() =>
                        import('src/views/inventory/PurchaseBills/CreatePurchaseReceive')
                      )}
                    />
                  )}

                  {permissionsArray && permissionsArray.includes('put_away') && (
                    <Route
                      exact
                      path="/app/receives/put_away/:id"
                      component={lazy(() =>
                        import('src/views/inventory/PurchaseReceives/StartPutAway')
                      )}
                    />
                  )}

                  {permissionsArray && permissionsArray.includes('batches') && (
                    <Route
                      exact
                      path="/app/order/batches"
                      component={lazy(() => import('src/views/orders/Batches'))}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('orders') && (
                    <Route
                      exact
                      path="/app/orders"
                      component={lazy(() => import('src/views/orders/ListOrders'))}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('orders') && (
                    <Route
                      exact
                      path="/app/order/view/:id"
                      component={lazy(() =>
                        import('src/views/orders/ListOrders/ViewOrder')
                      )}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('order_products') && (
                    <Route
                      exact
                      path="/app/orderproducts"
                      component={lazy(() => import('src/views/orders/ListOrderProducts'))}
                    />
                  )}

                  {permissionsArray && permissionsArray.includes('reverse_orders') && (
                    <Route
                      exact
                      path="/app/reverse_orders"
                      component={lazy(() => import('src/views/orders/ListReverseOrders'))}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('reverse_orders') && (
                    <Route
                      exact
                      path="/app/reverse_order/view/:id"
                      component={lazy(() =>
                        import('src/views/orders/ListReverseOrders/ViewOrder')
                      )}
                    />
                  )}

                  {permissionsArray && permissionsArray.includes('returns') && (
                    <Route
                      exact
                      path="/app/order/returns"
                      component={lazy(() => import('src/views/orders/Returns'))}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('returns') && (
                    <Route
                      exact
                      path="/app/order/returns/manifest/create"
                      component={lazy(() =>
                        import('src/views/orders/Returns/CreateManifest')
                      )}
                    />
                  )}

                  {permissionsArray && permissionsArray.includes('returns') && (
                    <Route
                      exact
                      path="/app/order/returns/manifest/:id/add"
                      component={lazy(() =>
                        import('src/views/orders/Returns/AddOrdersToManifest')
                      )}
                    />
                  )}

                  {permissionsArray && permissionsArray.includes('returns') && (
                    <Route
                      exact
                      path="/app/order/returns/manifests"
                      component={lazy(() =>
                        import('src/views/orders/Returns/ListReturnManifests')
                      )}
                    />
                  )}

                  {permissionsArray && permissionsArray.includes('printing') && (
                    <Route
                      exact
                      path="/app/order/printing"
                      component={lazy(() => import('src/views/orders/Printing'))}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('printing') && (
                    <Route
                      exact
                      path="/app/order/preview"
                      component={lazy(() => import('src/views/orders/Preview'))}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('qc') && (
                    <Route
                      exact
                      path="/app/order/qc"
                      component={lazy(() => import('src/views/orders/Qc'))}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('kitting') && (
                    <Route
                      exact
                      path="/app/order/kitting"
                      component={lazy(() => import('src/views/orders/Kitting'))}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('packing') && (
                    <Route
                      exact
                      path="/app/order/packing"
                      component={lazy(() => import('src/views/orders/PackingStation'))}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('packing') && (
                    <Route
                      exact
                      path="/app/order/packing/start"
                      component={lazy(() => import('src/views/orders/Packing'))}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('dispatch') && (
                    <Route
                      exact
                      path="/app/order/dispatch"
                      component={lazy(() => import('src/views/orders/Dispatch'))}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('dispatch') && (
                    <Route
                      exact
                      path="/app/order/dispatch/manifests"
                      component={lazy(() =>
                        import('src/views/orders/Dispatch/ListManifests')
                      )}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('dispatch') && (
                    <Route
                      exact
                      path="/app/order/dispatch/manifest/create"
                      component={lazy(() =>
                        import('src/views/orders/Dispatch/CreateManifest')
                      )}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('dispatch') && (
                    <Route
                      exact
                      path="/app/order/dispatch/manifest/:id/add"
                      component={lazy(() =>
                        import('src/views/orders/Dispatch/AddOrdersToManifest')
                      )}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('financials') && (
                    <Route
                      exact
                      path="/app/financials"
                      component={lazy(() =>
                        import('src/views/dashboards/FinancialDashboard')
                      )}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('analytics') && (
                    <Route
                      exact
                      path="/app/analytics"
                      component={lazy(() =>
                        import('src/views/dashboards/AnalyticsDashboard')
                      )}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('product_library') && (
                    <Route
                      exact
                      path="/app/product_library"
                      component={lazy(() => import('src/views/ProductLibrary'))}
                    />
                  )}
                  {permissionsArray && permissionsArray.includes('product_library') && (
                    <Route
                      exact
                      path="/app/product/:id"
                      component={lazy(() =>
                        import('src/views/ProductLibrary/ViewProduct')
                      )}
                    />
                  )}
                  <Redirect to="/404" />
                </Switch>
              </Suspense>
            </DashboardLayout>
          )}
        />

        <Route
          path="*"
          render={props => (
            <MainLayout {...props}>
              <Switch>
                <Route exact path="/home" component={HomeView} />
                <Redirect to="/404" />
              </Switch>
            </MainLayout>
          )}
        />

        <Redirect to="/404" />
      </Switch>
    </Suspense>
  );
}

export default Routes;
