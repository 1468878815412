import {
  Box,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Paper,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import LoadingScreen from 'src/components/LoadingScreen';
import axios from 'src/utils/axios';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  red: {
    backgroundColor: 'red',
    color: 'white'
  },
  orange: {
    backgroundColor: 'orange',
    color: 'white'
  },
  card: {
    width: 100,
    height: 60,
    marginTop: 5
  }
}));

export default function ActionBoard() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [actionBoardData, setActionBoardData] = useState();
  const [actionBoardInterval, setActionBoardInterval] = useState();
  const [loading, setLoading] = useState(true);

  const getActionBoardData = () => {
    axios
      .get('action_board_open')
      .then(r => {
        setActionBoardData(r.data);
        console.log('r.data', r.data);
        setLoading(false);
      })
      .catch(e => {
        if (e.response.data.errors) {
          const errors = e.response.data.errors;
          for (const field in errors) {
            if (errors.hasOwnProperty(field)) {
              const errorMessageArray = errors[field];
              enqueueSnackbar(errorMessageArray[0], {
                key: field,
                variant: 'error',
                anchorOrigin: ''
              });
            }
          }
        } else {
          enqueueSnackbar(e.response.data.message, {
            variant: 'error'
          });
        }
      });
  };

  useEffect(() => {
    setActionBoardInterval(
      setInterval(() => {
        getActionBoardData();
      }, 120000)
    );
    getActionBoardData();
  }, []);

  useEffect(() => {
    return () => {
      clearInterval(actionBoardInterval);
    };
  }, [actionBoardInterval]);

  if (loading) {
    return <LoadingScreen />;
  }

  const DataDisplay = ({ dataArray }) => {
    return (
      <Box p={4}>
        <Grid container spacing={2} justify="center" alignItems="center">
          {dataArray.map((obj, objIndex) => (
            <Grid item xs={1} key={obj.title}>
              <Box height="100%">
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  height="100%"
                >
                  <Typography variant="h5" gutterBottom>
                    {obj.shortTitle}
                  </Typography>
                  <Box display="flex" flexDirection="column">
                    {obj.data.map((dataObj, index) => (
                      <React.Fragment key={dataObj.title}>
                        <Box mt={3} mb={1}>
                          {objIndex === 0 ? (
                            <>
                              {index === 0 && (
                                <Typography variant="body2" align="center">
                                  SLA Breached
                                </Typography>
                              )}
                              {index === 1 && (
                                <Typography variant="body2" align="center">
                                  Breaching soon
                                </Typography>
                              )}

                              {index === 2 && (
                                <Typography variant="body2" align="center">
                                  In Queue
                                </Typography>
                              )}
                            </>
                          ) : (
                            <Typography
                              variant="body2"
                              align="center"
                              style={{ visibility: 'hidden' }}
                            >
                              Breaching soon
                            </Typography>
                          )}
                        </Box>
                        <Card
                          className={clsx(classes.card, {
                            [classes.red]:
                              dataObj.count > 0 &&
                              dataObj.title.toLowerCase() === 'sla breached',
                            [classes.orange]:
                              dataObj.count > 0 &&
                              dataObj.title.toLowerCase() === 'breaching soon'
                          })}
                        >
                          <CardContent className={classes.cardContent}>
                            <Typography variant="h3" align="center">
                              {dataObj.count}
                            </Typography>
                          </CardContent>
                        </Card>
                      </React.Fragment>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  return <DataDisplay dataArray={actionBoardData} />;
}
